
// Colors
$border-main-color              : #e1e4e8;
$nav-text-color                 : #586069;
$nav-text-hover-color           : #24292e;
$nav-background-color           : #fafbfc;
$nav-active-text-color          : #495057;
$nav-active-background-color    : #ffffff;
$nav-active-top-border-color    : #e36209;
$nav-disabled-text-color        : #eeeeee;

.st-theme-spark {
    position: relative;
    background: #fff;
    background-clip: border-box;
    background-color: #fff;
    border-radius: .2rem!important;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,.05);
    margin-bottom: 1.5rem!important;
    border: 1px solid #e9ecef;

    & > .nav {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.1)!important;

        & .nav-link {
            height: 100%;
            min-height: 100%;
            position: relative;

            &:after {
                background: #adb4bd;
                bottom: -1px;
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                transition: all .35s ease .15s;
            }

            &.inactive {
                color: #999;
                cursor: pointer;
            }

            &.active {
                color: #3b7ddd!important;
            }

            &.active:after {
                background: #3b7ddd!important;
                width: 100%;
            }

            &.inactive {
                color: #adb5bd;
            }

            &.done {
                color: #7ca8e8!important;
            }
        }
    }

    &.st-justified > .nav > li,
    &.st-justified > .nav .nav-link {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
    }

    &>.tab-content {
        overflow: hidden;
        position: relative;
        height: 100%;
    
        > .tab-pane {
            display: none;
        }

        .tab-pane {
            padding: 1.25rem!important;
        }
    }

    & .toolbar {
        padding: .8rem;
        text-align: right;

        & > .btn {
            cursor: pointer;
            display: inline-block;
            margin-left: .2rem;
            margin-right: .2rem;
            text-align: center;
            text-decoration: none;
            text-transform: none;
            user-select: none;
            vertical-align: middle;
            border: 1px solid #17a2b8;
            border-radius: .25rem;
            font-weight: 400;
            padding: .375rem .75rem;
            background-color: #3b7ddd;
            border-color: #3b7ddd;
            color: #fff;
        }

        &>.btn.disabled {
            opacity: .65;
        }
    }
}